import React, { useContext } from 'react';
import AwardList from '../award-list/award-list.component';
import AWARDS from './award-panel.utils';
import { Link } from "gatsby";

import { MenuContext } from '../../context'

const AwardPanel = () => {
  const menuContext = useContext(MenuContext);

  return (
    <section className='awards section'>
      <div className='row'>
        <div className='col'>
          <Link to="/awards" inert={menuContext.isMenuOpen ? "true" : null} className="homepage-link-titles" data-tealium>
            <h2 className="section__title">Awards</h2>
          </Link>
        </div>
      </div>
      <div className='row'>
        <div className='col awards__card-container'>
          <AwardList
            awardItems={AWARDS}
            additionalClass='col-xs-12 col-lg-3'
            orientation='vertical'
          />
        </div>
      </div>
    </section>
  )
}

export default AwardPanel;
