import React from 'react';
import GeneralAnchor from '../general-anchor/general-anchor.component';
import Calculator from '../../assets/calculator.inline.svg';
import NewWindow from '../../assets/new_window.inline.svg';

const AffordabilityCalculator = ({ children }) => (
  <section className='affordability-calculator'>
    <div className='affordability-calculator__texts'>
      <Calculator />
      <h2>Calculators</h2>
      <p>Find out how much your customer may be able to borrow with these quick and easy-to-use calculators.</p>
    </div>
    <div className='affordability-calculator__buttons'>
      <GeneralAnchor
        isBlank
        href='https://portal.intermediaries.hsbc.co.uk/affordabilitycalculator/affordabilitycalculatorpage.php'
        rel='noreferrer'
        isLink={true}
        additionalClass="residential-calculator"
        isTealiumLink={true}
      >
        Launch Residential calculator
        <NewWindow />
      </GeneralAnchor>
      <GeneralAnchor
        isBlank
        href='https://servicing.hsbc.co.uk/mortgages/btl-calculator/'
        rel='noreferrer'
        isLink={true}
        isTealiumLink={true}
      >
        Launch Buy to let calculator
        <NewWindow />
      </GeneralAnchor>
      {
        children && children
      }
    </div>
  </section>
);

export default AffordabilityCalculator;