import React, { useEffect } from 'react';

import Layout from '../components/layout';
import HomeHeader from '../components/home-header/home-header.component';
import ServiceStrip from '../components/service-strip/service-strip.component';
import RelatedLinks from '../components/related-links/related-links.component';
import AwardPanel from '../components/award-panel/award-panel.component';
import Rate from '../components/rate/rate.component';
import FaqNewsPanel from '../components/faq-news-panel/faq-news-panel.component';
import BrokerSupport from '../components/broker-support/broker-support.component';
import '../styles/app.scss';
import LinkToGreenPage from '../components/green-page/link-to-greenpage.component';

export default function Home() {

  const handleClick = (event) => {


    if (event.target.hasAttribute('data-tealium') || event.target.parentNode.hasAttribute('data-tealium')) {
      if (typeof window !== "undefined") {
        if(window.utag){
          window.utag.link({page_subcategory: "Home"})
        }
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);
    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);


  if (typeof window !== "undefined") {
    if(window.utag){

      window.utag.view({page_subcategory: "Home"})
    }
  }
  


  return (
    <Layout>
      <HomeHeader />
      <ServiceStrip />
      <LinkToGreenPage />
      <RelatedLinks />
      <AwardPanel />
      <Rate />
      <FaqNewsPanel />
      <BrokerSupport />
    </Layout>
  );
}