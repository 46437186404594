import React from 'react';

import RelatedLink from '../related-link/related-link.component';

import RELATED_LINKS_SCHEME from './related-lins.utils';

const RelatedLinks = () => (
  <section className='related-links row'>
    {
      RELATED_LINKS_SCHEME.length && RELATED_LINKS_SCHEME.map((card, index) => (
        <RelatedLink key={index} {...card} />
      ))
    }
  </section>
);

export default RelatedLinks;